import { FormControl, MenuItem, TextField, Stack, Typography, InputAdornment } from '@mui/material'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'

import { ChevronDownIcon } from '@/assets/icons/chevron-icons'

export const CustomizedChevronDownIcon = styled(ChevronDownIcon)`
  width: 20px;
  height: 20px;
  color: #667085;
`

export const iconContainerStyles = {
  '& .MuiSelect-icon': {
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#667085',
  },
  '& .MuiSelect-iconOpen': {
    transform: 'translateY(-50%) rotate(180deg)',
  },
}

export function OptionSelect({
  value,
  setValue,
  options = [],
  inputStyles = {},
  placeholder = 'Please Select',
  ...rest
}) {
  const handleChange = event => {
    event.preventDefault()
    event.stopPropagation()
    setValue(event.target.value)
  }

  return (
    <FormControl
      fullWidth
      size="small"
      sx={{
        ...iconContainerStyles,
        '& .MuiOutlinedInput-notchedOutline': { borderRadius: '16px !important' },
        '& .MuiFormControl-root': { mr: '0px !important' },
      }}
    >
      <TextField
        select
        SelectProps={{
          IconComponent: CustomizedChevronDownIcon,
          displayEmpty: true,
        }}
        sx={{
          ...inputStyles,
          ...iconContainerStyles,
        }}
        value={value}
        onChange={handleChange}
        {...rest}
      >
        <MenuItem disabled value="">
          {placeholder}
        </MenuItem>
        {options?.map(option => (
          <MenuItem key={option.value} value={option?.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  )
}

export function FieldWithLabel({ label, required = false, children }) {
  return (
    <Stack gap="6px" width="100%">
      <Typography
        sx={{
          color: '#344054',
          fontWeight: 500,
          lineHeight: '20px',
        }}
      >
        {label}
        {required ? <span style={{ color: '#C11574' }}>*</span> : null}
      </Typography>
      {children}
    </Stack>
  )
}

export function EmailInput({ email, handleInputChange, onEmailValid = () => null }) {
  const [emailError, setEmailError] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)

  const validateEmail = email => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

    return emailRegex.test(email)
  }

  const handleEmailChange = e => {
    const value = e.target.value

    handleInputChange(value)

    if (value === '' || validateEmail(value)) {
      setEmailError(false)
      setIsEmailValid(true)
    } else {
      setEmailError(true)
      setIsEmailValid(false)
    }
  }

  useEffect(() => {
    onEmailValid(isEmailValid)
  }, [isEmailValid, onEmailValid])

  return (
    <TextField
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <EmailIcon />
          </InputAdornment>
        ),
      }}
      error={emailError}
      helperText={emailError ? 'Please enter a valid email address' : ''}
      placeholder="Enter your work email"
      sx={{
        borderColor: 'red',
        '& .Mui-error': { borderColor: '#E51042 !important' },
        '& .MuiInputBase-root': { height: 44 },
        '& .MuiOutlinedInput-notchedOutline': {
          borderRadius: '16px !important',
        },
      }}
      value={email}
      onChange={handleEmailChange}
    />
  )
}

export function EmailIcon(props) {
  return (
    <svg fill="none" height={20} viewBox="0 0 20 20" width={20} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m1.668 5.834 6.804 4.762c.551.386.827.579 1.126.654.265.066.542.066.806 0 .3-.075.576-.268 1.127-.654l6.804-4.762M5.668 16.667h8.667c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 0 0 1.092-1.092c.273-.535.273-1.235.273-2.635V7.334c0-1.4 0-2.1-.273-2.635a2.5 2.5 0 0 0-1.092-1.093c-.535-.272-1.235-.272-2.635-.272H5.668c-1.4 0-2.1 0-2.635.272A2.5 2.5 0 0 0 1.94 4.699c-.272.534-.272 1.234-.272 2.635v5.333c0 1.4 0 2.1.272 2.635a2.5 2.5 0 0 0 1.093 1.092c.535.273 1.235.273 2.635.273"
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.667}
      />
    </svg>
  )
}

export function FeaturesList({ category, features }) {
  return (
    <Stack
      flexDirection="column"
      gap="20px"
      sx={{
        width: 240,
      }}
    >
      <Typography
        sx={{
          fontSize: 18,
          fontWeight: 600,
          lineHeight: '28px',
        }}
      >
        {category}
      </Typography>
      <Stack flexDirection="column" gap="16px">
        {/* Item */}
        {features.map(feature => (
          <Stack key={feature} direction="row" gap="8px">
            <CheckIcon style={{ flexShrink: 0 }} />
            <Typography
              sx={{
                fontSize: 16,
                lineHeight: '24px',
              }}
            >
              {feature}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  )
}

export function Ellipse(props) {
  return (
    <svg fill="none" height={121} viewBox="0 0 127 121" width={127} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_f_149_32727)">
        <ellipse cx={63.5} cy={60.5} fill="#EE46BC" rx={23.5} ry={20.5} />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={121}
          id="filter0_f_149_32727"
          width={127}
          x={0}
          y={0}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_149_32727" stdDeviation={20} />
        </filter>
      </defs>
    </svg>
  )
}

export function EllipseGroup(props) {
  return (
    <svg fill="none" height={108} viewBox="0 0 310 108" width={310} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#a)">
        <path
          d="M292.885 40.247c-.728 1.412-1.022 2.987-.826 4.435.225 1.668 1.09 3.155 2.37 4.08.749.539 1.672.912 2.746 1.108q.726.131 1.437.13c2.108 0 4.052-.847 5.194-2.329 1.376-1.784 1.433-4.04 1.379-6.014-.078-2.877-1.593-4.934-4.05-5.503-3.075-.708-6.698 1.085-8.25 4.093"
          fill="#EE46BC"
        />
      </g>
      <path
        d="M143.977 10.715c.959.77 2.22 1.213 3.644 1.277q.183.009.366.008c2.194 0 4.277-.909 5.634-2.475 1.602-1.851 2.031-4.527 1.066-6.655-1.528-3.376-6.088-3.294-8.688-2.18-1.894.811-3.319 2.417-3.813 4.292-.556 2.117.163 4.42 1.791 5.733"
        fill="#FEC84B"
      />
      <path
        d="M12.165 45.01a5.6 5.6 0 0 0-.148-1.766c-.701-2.956-3.367-5.162-6.34-5.244-2.579.019-4.662 1.533-5.446 4.19-.683 2.32.185 4.993 2.113 6.501C3.446 49.555 4.818 50 6.207 50a6.4 6.4 0 0 0 2.697-.59c1.857-.86 3.106-2.544 3.261-4.4"
        fill="#36BFFA"
      />
      <g filter="url(#b)">
        <path
          d="M241.563 103.033c1.84.646 3.63.967 5.343.967q1.09.001 2.138-.173c3.116-.526 5.691-2.283 6.721-4.593 1.121-2.512.238-5.049-.344-6.724-1.261-3.626-4.573-5.73-8.652-5.492-5.112.3-9.891 4.093-10.656 8.452-.274 1.57-.048 2.975.677 4.182.884 1.466 2.447 2.574 4.773 3.381"
          fill="#47CD89"
        />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={22}
          id="a"
          width={21.199}
          x={288}
          y={32}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_149_32732" stdDeviation={2} />
        </filter>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height={25}
          id="b"
          width={28.305}
          x={232}
          y={83}
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur result="effect1_foregroundBlur_149_32732" stdDeviation={2} />
        </filter>
      </defs>
    </svg>
  )
}

export function CheckIcon(props) {
  return (
    <svg fill="none" height={20} viewBox="0 0 21 20" width={21} xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M.5 10c0-5.523 4.477-10 10-10s10 4.477 10 10-4.477 10-10 10-10-4.477-10-10" fill="#DD2590" />
      <path d="m6.75 10 2.5 2.5 5-5" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} />
    </svg>
  )
}
