import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import LogRocket from 'logrocket'

import PermissionsReducer from './permissionsSlice'
import OrganizationsReducer from './organizationsSlice'
import UserReducer from './userSlice'
import SearchReducer from './searchSlice'
import AppStateReducer from './appStateSlice'

export default configureStore({
  reducer: {
    permissions: PermissionsReducer,
    organization: OrganizationsReducer,
    user: UserReducer,
    search: SearchReducer,
    app: AppStateReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk,
      serializableCheck: false,
    }).concat(
      LogRocket.reduxMiddleware({
        stateSanitizer: function ({ user, ...rest }) {
          const isTeacher = user?.profile?.roles?.includes('ADMIN')

          return {
            ...rest,
            user: {
              ...(user || {}),
              profile: {
                ...(user?.profile || {}),
                displayName: isTeacher ? user?.profile?.displayName : 'Student',
                email: isTeacher ? user?.profile?.email : null,
              },
              auth: null,
            },
          }
        },
        actionSanitizer: function (action) {
          // scrib the payload of the action
          if (action?.type === 'user/setUserProfile') {
            return {
              ...(action || {}),
              payload: {
                ...(action?.payload || {}),
                displayName: action?.payload?.roles?.includes('ADMIN') ? action?.payload?.displayName : 'Student',
                email: action?.payload?.roles?.includes('ADMIN') ? action?.payload?.email : null,
              },
            }
          } else if (action?.type === 'user/setUserAuth') {
            return {
              ...(action || {}),
              payload: null,
            }
          }
        },
      }),
    ),
})
