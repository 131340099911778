import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { InstantSearch } from 'react-instantsearch'

import useFromState from './hooks/useFromState'
import Splash from './components/Splash/Splash'
import { algolia_client } from './utils/AlgoliaSearch'
import { getOrgById, retrieveId } from './utils/getOrgById'
import ProductsDialog from './components/PricingPlansTable/ProductsDialog'
import { setProductsModalOpen } from './Redux/appStateSlice'
import SessionTimer from './components/SessionTimer'
import { MediaAreaPage } from './views/MediaArea'
import { fetchUserRoles, setRoles } from './Redux/userSlice'

const District = React.lazy(() => import('./views/AdminPanels/District'))
const School = React.lazy(() => import('./views/AdminPanels/School'))
const Dashboard = React.lazy(() => import('./views/AdminPanels/pages/Dashboard'))
const Schools = React.lazy(() => import('./views/AdminPanels/pages/Schools'))
const Plans = React.lazy(() => import('./views/AdminPanels/pages/Plans'))
const Plan = React.lazy(() => import('./views/AdminPanels/pages/Plan'))
const Settings = React.lazy(() => import('./views/AdminPanels/pages/Settings'))
const Cohorts = React.lazy(() => import('./views/AdminPanels/pages/Cohorts'))
const Users = React.lazy(() => import('./views/AdminPanels/pages/Users'))
const AcceptInvitation = React.lazy(() => import('./views/AdminPanels/pages/AcceptInvitation'))

const ActivityView = React.lazy(() => import('./views/NewAssignment/ActivityView'))
const BuilderView = React.lazy(() => import('./views/NewBuilder/BuilderView'))
const AssignmentAnalyticsPage = React.lazy(() => import('./views/AssignmentAnalytics'))
const Onboarding = React.lazy(() => import('./views-auth/Onboarding/index'))
const Login = React.lazy(() => import('./views-auth/Login/index'))
const CreateAccount = React.lazy(() => import('./views-auth/CreateAccount'))
const JoinCourse = React.lazy(() => import('./views-auth/JoinCourse'))
const SettingsPage = React.lazy(() => import('./views/Settings/index'))
const CleverAuth = React.lazy(() => import('./views-auth/CleverAuth'))
const SubscriptionPlans = React.lazy(() => import('./views/SubscriptionPlans'))
const Library = React.lazy(() => import('./views/Library'))
const Admin = React.lazy(() => import('./views/Admin'))
const Gradebook = React.lazy(() => import('./views/Gradebook'))
const ThankYou = React.lazy(() => import('./views/ThankYou'))
const SetPreviewPage = React.lazy(() => import('./views/SetPreview'))
const SectionsPage = React.lazy(() => import('./views/Sections'))
const SectionAnalytics = React.lazy(() => import('./views/SectionAnalytics'))
const AppLayout = React.lazy(() => import('./AppLayout'))
const Discover = React.lazy(() => import('./views/DiscoverSets'))
const Home = React.lazy(() => import('./views/Home'))
const Spaces = React.lazy(() => import('./views/Spaces'))
const Support = React.lazy(() => import('./views/Support'))
const Space = React.lazy(() => import('./views/Space/Space.jsx'))
const Classrooms = React.lazy(() => import('./views/Classrooms'))
const LtkiAuth = React.lazy(() => import('./views-auth/LTI/LtikAuth'))
const LTIContentSelecttion = React.lazy(() => import('./views/LTIContentSelection'))
const Checkpoint = React.lazy(() => import('./views/Checkpoint'))
const Rubric = React.lazy(() => import('./views/Rubric'))
const TeacherProLanding = React.lazy(() => import('./views-auth/TeacherPro/Landing'))

function App() {
  const dispatch = useDispatch()
  const { authLoaded, profileLoaded, auth } = useSelector(({ user }) => user)
  const loading = !(authLoaded && profileLoaded)
  const location = useLocation()
  const { from, updateFrom, prevLocation } = useFromState()
  const { productsModalOpen = false, highlightedPlan } = useSelector(({ app }) => app)

  React.useEffect(() => {
    if (auth && auth.uid && (location.pathname?.includes('school') || location.pathname?.includes('district'))) {
      dispatch(fetchUserRoles({ email: auth?.email, pathname: location.pathname }))
    }
  }, [auth, dispatch, location.pathname])

  return (
    <>
      {loading ? (
        <Splash />
      ) : (
        <>
          <SessionTimer userId={auth?.uid} />
          <ProductsDialog
            highlightedPlan={highlightedPlan}
            open={productsModalOpen}
            onClose={() => dispatch(setProductsModalOpen(false))}
          />
          <AppRoutes from={from} prevLocation={prevLocation} updateFrom={updateFrom} />
        </>
      )}
    </>
  )
}

export default App

function AppRoutes({ from, updateFrom, prevLocation }) {
  return (
    <Routes>
      {/* is not Authenticated */}
      <Route element={<TeacherProLanding />} path="/teacherpro" />
      <Route element={<JoinCourse />} path="/join/:id" />
      <Route element={<MediaAreaPage />} path="/media-area/:id" />
      <Route element={<CleverAuth />} path="/clever" />
      {/* <Route element={<CleverAuthMobile />} path="/clever-mobile" /> */}
      <Route element={<LtkiAuth />} path="/lti" />
      <Route element={<LtkiAuth isDeeplinking />} path="/lti-deeplinking" />
      <Route element={<AcceptInvitation />} path="/accept-invite" />

      <Route element={<PublicOutlet redirect={prevLocation ? prevLocation : from} />} path="/auth">
        <Route element={<Login />} path="/auth/login" />
        <Route element={<CreateAccount />} path="/auth/create-account" />
        <Route element={<Navigate to="/auth/login" />} path="*" />
      </Route>
      <Route element={<SetPreviewPage />} path="/preview/:id" />
      <Route element={<ActivityView />} path="/activity/:id" />

      <Route element={<PrivateOutlet updateFrom={updateFrom} />} path="/">
        {/* District Routes */}
        <Route element={<District />} path="/district/:orgId">
          {/* <Route index element={<Dashboard />} /> */}
          <Route index element={<Users />} path="users" />
          {/* <Route element={<Cohorts />} path="cohorts" /> */}
          {/* <Route element={<Schools />} path="schools" /> */}
          <Route element={<Outlet />} path="plans">
            <Route index element={<Plans />} />
            <Route element={<Plan />} path=":planId" />
          </Route>
          <Route element={<Settings />} path="settings" />
        </Route>
        {/* School Routes */}
        <Route element={<School />} path="/school/:schoolId">
          {/* <Route index element={<Dashboard />} /> */}
          <Route index element={<Users />} path="users" />
          {/* <Route element={<Cohorts />} path="cohorts" /> */}
          <Route element={<Outlet />} path="plans">
            <Route index element={<Plans />} />
            <Route element={<Plan />} path=":planId" />
          </Route>
          <Route element={<Settings />} path="settings" />
        </Route>
        {/* is Authenticated and has profile */}
        <Route element={<MainNavigationOutlet />} path="/">
          <Route element={<Home />} path="/" />
          <Route element={<Discover />} path="/d" />
          <Route element={<LTIContentSelecttion />} path="/lti-content-selection" />
          <Route element={<SectionsPage />} path="/s/:id" />
          <Route element={<SectionAnalytics />} path="/s/:id/analytics" />
          <Route element={<Gradebook />} path="/g" />
          <Route element={<Support />} path="/support" />
          <Route element={<Classrooms />} path="/classrooms" />
          <Route element={<AdminRoute />} path="/admin/:orgId">
            <Route index element={<Admin />} />
            <Route element={<Admin isTeacherRoute />} path="teacher/:teacherId" />
          </Route>

          <Route element={<Library />} path="/l" />
          <Route element={<AssignmentAnalyticsPage />} path="/a/:id" />
          <Route element={<SettingsPage />} path="/settings" />
          <Route element={<ActivityView />} path="/assignment/:id" />
          {/* <Route element={<Builder />} path="/b/:id" /> */}
          <Route element={<SubscriptionPlans />} path="/pricing" />
          <Route element={<ThankYou />} path="/thank-you" />
          <Route element={<Navigate to="/" />} path="*" />
          <Route element={<Spaces />} path="/spaces" />
          <Route element={<Space />} path="/spaces/:id" />
          <Route element={<BuilderView />} path="/b/:setId" />
          <Route element={<Checkpoint />} path="/checkpoint/:id" />
          <Route element={<Rubric />} path="/rubric/:id" />
        </Route>
        {/* is Authenticated and does not have profile - Teacher/Student Onboarding */}
        <Route element={<Onboarding />} path="/onboarding" />
      </Route>
    </Routes>
  )
}

function PrivateOutlet({ updateFrom }) {
  const auth = useSelector(({ user }) => user.auth)
  const profile = useSelector(({ user }) => user.profile)
  const location = useLocation()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!auth?.uid && updateFrom) {
      updateFrom(location)
    }
  }, [auth, profile, navigate, location])

  if (!auth?.uid) {
    return <Navigate to="/auth/login" />
  }

  return <Outlet />
}
function AdminRoute() {
  const { adminOrgs, managerOrgs } = useSelector(({ organization }) => organization)
  const { orgId } = useParams()
  const formattedOrgId = retrieveId(orgId)
  const adminOrgObj = getOrgById(adminOrgs || [], formattedOrgId)
  const managerOrgObj = getOrgById(managerOrgs || [], formattedOrgId)
  const isAllowed = adminOrgObj || managerOrgObj
  const isManager = managerOrgObj?.isManager

  if (!isAllowed) {
    return <Navigate to="/" />
  }

  if (isManager) {
    const path = window.location.pathname

    if (path.includes('/teacher/')) {
      return <Navigate to="/" />
    }
  }

  return <Outlet />
}

function PublicOutlet({ redirect }) {
  const { auth } = useSelector(({ user }) => user)

  if (auth?.uid) {
    return <Navigate to={redirect} />
  }

  return <Outlet />
}

const foucsRoutes = ['b', 'assignment', 'activity', 'lti-content-selection', 'checkpoint', 'rubric']

function MainNavigationOutlet() {
  const profile = useSelector(({ user }) => user.profile)
  const location = useLocation()
  const route = location.pathname.split('/')[1]
  const path = window.location.pathname

  const indexName = 'speakable-sets-live'
  const myCreationsIndex = 'my creations index'

  if (profile?.roles && foucsRoutes.includes(route)) {
    return <Outlet />
  }
  if (!profile?.roles) {
    return <Navigate to="/onboarding" />
  }

  return (
    <InstantSearch
      routing
      future={{
        preserveSharedStateOnUnmount: true,
      }}
      indexName={path === '/l' ? myCreationsIndex : indexName}
      searchClient={algolia_client}>
      <AppLayout />
    </InstantSearch>
  )
}
