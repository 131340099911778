import { createSlice } from '@reduxjs/toolkit'
import { httpsCallable } from 'firebase/functions'

import { functions } from '../firebase'
const loadOrgPermissions = httpsCallable(functions, 'loadOrgPermissions')

export const organizationsSlice = createSlice({
  name: 'organization',
  initialState: {
    loaded: false,
    loading: false,
  },
  reducers: {
    setOrganization: (state, action) => {
      state = { ...state, ...action.payload, loaded: true, loading: false }

      return state
    },
    clearOrganization: state => {
      state = {
        loaded: false,
        loading: false,
      }

      return state
    },
  },
})

export const { setOrganization } = organizationsSlice.actions

export default organizationsSlice.reducer

const excludedDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'msn.com',
  'live.com',
  'ymail.com',
  'mail.com',
  'inbox.com',
  'protonmail.com',
  'zoho.com',
  'test.com',
]

export const loadOrganization = email => {
  return async (dispatch, getState) => {
    const emailDomain = email?.split?.('@')?.[1]
    const {
      user: { profile },
    } = getState()

    if (!profile || !profile.roles || !profile.roles.includes('ADMIN')) {
      return
    }
    try {
      if (excludedDomains.includes(emailDomain)) {
        return
      }
      const { data } = await loadOrgPermissions()

      if (!data.success) {
        console.log('No organization found')

        return
      }

      dispatch(setOrganization(data))
    } catch (error) {
      console.log('loadOrgPermissions', error)
    }
  }
}
